import React, { useState, useEffect } from "react";

function Contact({ viewState, action }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors([]);
    setSuccessMessage("");

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);

    fetch("https://www.danielchambe.rs/contact.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccessMessage(data.message);
          // Clear form fields on successful submission
          setName("");
          setEmail("");
          setMessage("");
        } else {
          setErrors(data.errors);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (successMessage === "Email sent successfully.") {
      setTimeout(() => {
        setSuccessMessage("");
      }, 4000);
    }
  }, [successMessage]);

  return (
    <div
      className={
        viewState
          ? "bg-[#F2E3D5] fixed top-0 left-0 w-screen h-screen overflow-auto p-10 slide-top-to-bottom sliding-top-to-bottom delay-250"
          : "hidden"
      }
    >
      <div className="flex justify-end mb-1">
        <button onClick={action} className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-8 h-8 border-2 border-black bg-white rounded-full p-1 hover:rotate-180 transform transition-transform duration-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={3}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      {/* forms */}
      <div className="flex justify-center items-center mt-36">
        <div className="w-3/5">
          <form className="space-y-10" onSubmit={handleSubmit}>
            {errors.length > 0 && (
              <div>
                <ul>
                  {errors.map((error, index) => (
                    <li className="font-bold text-[#FF0000]" key={index}>
                      {error}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              className="w-full bg-transparent border-b border-black focus:outline-none placeholder-black font-thin text-6xl text-black h-20"
              placeholder="Name"
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "Name")}
              onChange={(e) => setName(e.target.value)}
            />

            <input
              type="email"
              id="email"
              name="email"
              value={email}
              className="w-full bg-transparent border-b border-black focus:outline-none placeholder-black font-thin text-6xl text-black h-20"
              placeholder="Email"
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "Email")}
              onChange={(e) => setEmail(e.target.value)}
            />

            <textarea
              id="message"
              name="message"
              value={message}
              className="custom-textarea w-full bg-transparent border-b border-black focus:outline-none placeholder-black font-thin text-6xl text-black "
              placeholder="Message"
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "Message")}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <div className="flex space-x-5 items-center">
              <button
                className="bg-white rounded-md p-2 uppercase border border-transparent hover:border-black cursor-pointer"
                type="submit"
              >
                Submit
              </button>
              {successMessage && (
                <div className="font-bold text-[#38b238]">
                  <p>{successMessage}</p>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
