import React from "react";

function Projects({ viewState, action }) {
  const projects = [
    {
      number: "01",
      title: "Cryptosift",
      subtext: "News aggrogator and token portfolio.",
      text: "Cryptosifter is a comprehensive Crypto News Aggregator and token portfolio specifically designed for the Cardano blockchain ecosystem. The project's primary objective is to provide users with a curated selection of up-to-date and relevant news content from diverse sources within the Cardano community. Additionally, it offers a breakdown of token allocation within wallets, allowing users to easily track and manage their Cardano-based investments.",
      link: "http://cryptosift.xyz",
      code: "https://gitlab.com/danielchambers/cryptosifter",
      image: "images/projects/cryptosift.png",
      stack: [
        "Python",
        "Celery",
        "Tornado",
        "Javascript",
        "React",
        "ExpressJS",
        "TailwindCSS",
        "Digital Ocean",
      ],
    },
    {
      number: "02",
      title: "YackSpace",
      subtext: "Twitter Spaces tracker.",
      text: "Yackspace is a Twitter Spaces aggregator that focuses on cryptocurrency-related conversations. It specifically searches for Spaces discussing Cardano, Bitcoin, and Ethereum. The aggregator pulls in the latest Spaces and displays them in an easy-to-use interface, allowing users to quickly find and join relevant conversations. Additionally, the project aims to help users discover new Spaces and communities within the crypto space.",
      link: "https://yack.space/",
      code: "https://gitlab.com/danielchambers/yackspace",
      image: "images/projects/yakspace.png",
      stack: [
        "Python",
        "Javascript",
        "React",
        "ExpressJS",
        "TailwindCSS",
        "Firebase",
      ],
    },
  ];

  return (
    <div
      className={
        viewState
          ? "bg-[#F2E3D5] fixed top-0 left-0 w-screen h-screen overflow-auto p-10 slide-top-to-bottom sliding-top-to-bottom delay-250"
          : "hidden"
      }
    >
      <div className="flex justify-end mb-1">
        <button onClick={action} className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-8 h-8 border-2 border-black bg-white rounded-full p-1 hover:rotate-180 transform transition-transform duration-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={3}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div>
        {projects.map((project) => (
          <div className="flex">
            <div className="w-1/4 p-2 mr-10">
              <div className="space-y-2">
                <h1 className="text-6xl font-thin">{project.number}</h1>
                <h2 className="text-5xl font-black">{project.title}</h2>
                <p className="font-black">{project.subtext}</p>
              </div>
              <div className="mt-5">
                <p>{project.text}</p>
              </div>
              <div className="mt-5 space-y-2">
                <div>
                  <span className="font-bold">Demo: </span>{" "}
                  <a
                    className="hover:bg-white"
                    target="_blank"
                    rel="noreferrer"
                    href={project.link}
                  >
                    {project.link}
                  </a>
                </div>
                <div>
                  <span className="font-bold">Code: </span>{" "}
                  <a
                    className="hover:bg-white"
                    target="_blank"
                    rel="noreferrer"
                    href={project.code}
                  >
                    Gitlab.com
                  </a>
                </div>
              </div>
              <div className="flex flex-wrap mt-5 gap-2">
                {project.stack.map((item) => (
                  <span className="bg-white rounded-md p-2 text-sm ">
                    {item}
                  </span>
                ))}
              </div>
            </div>
            <div className="w-3/4">
              <img src={project.image} alt="project example" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
