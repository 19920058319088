import "./Links.css";

import React, { useEffect, useState } from "react";
const LinkItem = ({ children, time }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, time);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div className={`fade-in ${isVisible ? "fade-in-visible" : ""}`}>
      {children}
    </div>
  );
};

export default LinkItem;
