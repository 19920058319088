function Landing({ handleShowMenu }) {
  return (
    <div className="absolute flex flex-col p-20 mt-12 text-[#F2E3D5]">
      <div className="flex text-7xl font-poppins font-black tracking-wider">
        <span>Daniel Chambers</span>
      </div>

      <div class="mt-5 w-1/2">
        <h2 className="font-poppins text-xl">
          Motivated backend developer with a passion for APIs, scalability, and
          automation, seeking employment opportunities to expand and apply my
          skills to solve complex problems.
        </h2>
      </div>

      <div onClick={handleShowMenu} className="mt-10 hover:cursor-pointer">
        <span className="w-12 h-12 flex items-center justify-center text-6xl bg-[#F24C27] rounded-full relative">
          <span className="absolute w-full h-full border-4 rounded-full border-[#F2E3D5] animate-ping"></span>
        </span>
      </div>
    </div>
  );
}

export default Landing;
