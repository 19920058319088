import { useState } from "react";
import Contact from "./Contact";
import Projects from "./Projects";
import Menu from "../components/Menu";
import Landing from "./Landing";

function Home() {
  const [showMenu, setShowMenu] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [openPortfolio, setOpenPortfolio] = useState(false);
  const toggleContact = () => setOpenContact(!openContact);
  const togglePortfolio = () => setOpenPortfolio(!openPortfolio);

  const handleShowMenu = (show) => {
    setShowMenu(show);
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen overflow-hidden">
      <div className="absolute inset-0 flex items-center justify-center">
        <iframe
          src="https://www.youtube.com/embed/R2lcf45uvZ8?autoplay=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;loop=1&amp;mute=1&amp;playlist=R2lcf45uvZ8&amp;modestbranding=1"
          className="w-full h-full"
          style={{ transform: "scale(1.2)" }}
          frameBorder="0"
          allowFullScreen
          title="YouTube Video"
        />
      </div>
      <div className="absolute inset-0 bg-black opacity-70" />
      <div className="absolute inset-0 backdrop-filter backdrop-blur-sm" />
      {showMenu ? (
        <div class="fixed top-0 left-0 w-screen h-screen bg-[#F24C27] overflow-hidden">
          <div class="absolute inset-0 flex items-center justify-center">
            <Menu
              toggleContact={toggleContact}
              togglePortfolio={togglePortfolio}
            />
            <Contact viewState={openContact} action={toggleContact} />
            <Projects viewState={openPortfolio} action={togglePortfolio} />
          </div>
        </div>
      ) : (
        <Landing handleShowMenu={handleShowMenu} />
      )}
    </div>
  );
}

export default Home;
