import LinkItem from "./LinkItem";

function Menu({ toggleContact, togglePortfolio }) {
  return (
    <ul className="flex flex-col space-y-3 text-8xl font-black text-[#F2E3D5]">
      <LinkItem time={300}>
        <div onClick={togglePortfolio} className="relative">
          <span className="text-sm p-4 font-light">(001)</span>
          <span className="animate-strike-through">Projects</span>
        </div>
      </LinkItem>

      <LinkItem time={600}>
        <div onClick={toggleContact} className="relative">
          <span className="text-sm p-4 font-light">(002)</span>
          <span className="animate-strike-through">Contact</span>
        </div>
      </LinkItem>

      <LinkItem time={900}>
        <a
          className="relative"
          href="https://gitlab.com/danielchambers"
          rel="noreferrer"
          target="_blank"
        >
          <span className="text-sm p-4 font-light">(003)</span>
          <span className="animate-strike-through">Gitlab</span>
        </a>
      </LinkItem>

      {/* <LinkItem time={1200}>
        <a
          className="relative"
          href="https://linkedin.com/in/danielcchambers"
          rel="noreferrer"
          target="_blank"
        >
          <span className="text-sm p-4 font-light">(004)</span>
          <span className="animate-strike-through">Linkedin</span>
        </a>
      </LinkItem> */}
    </ul>
  );
}

export default Menu;
